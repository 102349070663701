import {
  Container,
  Box,
  VStack,
  Text,
  Link,
  Stack,
  StackDivider,
} from '@chakra-ui/react';
import { motion } from 'framer-motion';
import useTranslation from 'next-translate/useTranslation';
import { useRouter } from 'next/router';

export default function Footer({ minH }: { minH?: string }) {
  const router = useRouter();
  const { t } = useTranslation('landing-page');
  return (
    <Box
      pt={[5, 6, 9, 12]}
      bg={'lpFooterColor'}
      as={motion.div}
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
      transition="1s linear"
      viewport={{ once: true }}
      position={'relative'}
      // zIndex={"overlay"}
      bottom={0}
      minH={minH}
      w={'full'}
    >
      <Container zIndex={0} maxW={{ base: 'full', md: '5xl', xl: '1690px' }}>
        <VStack zIndex={0} spacing={5}>
          <Stack
            direction={['column', 'column', 'row']}
            spacing={2}
            align="center"
            divider={<StackDivider borderColor="gray.600" />}
          >
            {/* <StackDivider
              display={{ base: 'none', md: 'flex' }}
              alignSelf={'center'}
              w="4px"
              h="26px"
              bgColor="black"
            /> */}
            <Link
              fontSize={['xs', 'sm']}
              fontWeight={600}
              color="white"
              _hover={{ textDecor: 'underline' }}
              onClick={() => {
                router.push('/terms');
              }}
            >
              {t('footer-link-lbl-terms')}
            </Link>
            <Link
              fontSize={['xs', 'sm']}
              fontWeight={600}
              color="white"
              _hover={{ textDecor: 'underline' }}
              href="/regulations"
            >
              {t('footer-link-lbl-regulations')}
            </Link>
            <Link
              fontSize={['xs', 'sm']}
              fontWeight={600}
              color="white"
              _hover={{ textDecor: 'underline' }}
              onClick={() => {
                router.push('/faq');
              }}
            >
              {t('footer-link-lbl-faq')}
            </Link>
          </Stack>
          <Text color="white" fontSize={['xs', 'sm']} textAlign="center">
            {t('footer-text-regulation')}
          </Text>
          <Text color="white" fontSize={['xs', 'sm']} textAlign="center">
            {t('footer-text-copyright')}
          </Text>
        </VStack>
      </Container>
    </Box>
  );
}
