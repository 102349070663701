import {
  Avatar,
  Box,
  Flex,
  HStack,
  VStack,
  Text,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Icon,
  Center,
  Divider,
  Button,
} from '@chakra-ui/react';
import { useSession, signOut } from 'next-auth/react';
import router from 'next/router';
import { FaAward } from 'react-icons/fa';
import { FiChevronDown } from 'react-icons/fi';
import { MdLogin, MdLogout, MdOutlineQuiz } from 'react-icons/md';
import { IoTicketOutline } from 'react-icons/io5';
import useTranslation from 'next-translate/useTranslation';

export default function UserProfile() {
  const { data: session } = useSession();
  const { t } = useTranslation('landing-page');
  return (
    <HStack zIndex={1}>
      {/* <IconButton
    size="lg"
    variant="ghost"
    aria-label="open menu"
    icon={<FiBell />}
  /> */}
      {!session && (
        <Button
          borderWidth="3px"
          borderColor="#00FF23"
          variant="outline"
          _hover={{ bg: 'orange.500' }}
          _active={{ bg: 'orange.500' }}
          leftIcon={<MdLogin color="white" />}
          colorScheme="orange"
          onClick={() => {
            router.push('/login');
          }}
        >
          <Text color="white">{t('header-button-txt-login')}</Text>
        </Button>
      )}
      {session && (
        <Flex alignItems="center">
          <Menu>
            <MenuButton
              py={2}
              transition="all 0.3s"
              _focus={{ boxShadow: 'none' }}
            >
              <HStack spacing="4">
                <Avatar size="md" name={session.user.email} />
                <VStack
                  display={{ base: 'none', xl: 'flex' }}
                  align="flex-start"
                  spacing="1px"
                  ml="2"
                >
                  <Text color="white">{session.user.email}</Text>
                  {/* {session?.user?.phone ?? (
                    <Text color="white" as="i" onClick={() => router.push("/phone")} cursor="pointer" fontSize='sm' >
                      nenhum telefone cadastrado
                    </Text>
                  )} */}
                </VStack>
              </HStack>
            </MenuButton>
            <MenuList
              color="blackAlpha.800"
              bg="white"
              borderColor="orange.200"
            >
              <MenuItem
                onClick={() => {
                  router.push('/account/prizes');
                }}
                icon={<FaAward />}
              >
                {t('header-menu-txt-prizes')}
              </MenuItem>
              <MenuItem
                onClick={() => {
                  router.push('/account/luckynumbers');
                }}
                icon={<IoTicketOutline />}
              >
                {t('header-menu-txt-luckynumbers')}
              </MenuItem>
              <MenuItem
                onClick={() => {
                  router.push('/account/points');
                }}
                icon={<IoTicketOutline />}
              >
                {t('header-menu-txt-points')}
              </MenuItem>
              <MenuItem
                onClick={() => {
                  router.push('/account/results');
                }}
                icon={<MdOutlineQuiz />}
              >
              {t('header-menu-txt-results')}
              </MenuItem>
              {/* <MenuItem
                onClick={() => { router.push("/phone") }}
                icon={<BsTelephone />}>Cadastrar telefone</MenuItem> */}
              <MenuDivider display={{ base: 'flex', xl: 'none' }} />
              <MenuItem
                display={{ base: 'flex', xl: 'none' }}
                icon={<MdLogout />}
                onClick={() => {
                  signOut();
                }}
              >
                {t('header-menu-txt-signout')}
              </MenuItem>
            </MenuList>
          </Menu>
          <Box>
            <FiChevronDown color="white" />
          </Box>
          <Center height="30px" px={2} display={{ base: 'none', xl: 'flex' }}>
            <Divider orientation="vertical" />
          </Center>
          <Box display={{ base: 'none', xl: 'flex' }}>
            <Icon
              aria-label="sair"
              cursor="pointer"
              color="white"
              as={MdLogout}
              w={8}
              h={8}
              onClick={() => {
                signOut();
              }}
            />
          </Box>
        </Flex>
      )}
    </HStack>
  );
}
