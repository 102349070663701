import NextLink from 'next/link';
import { Flex, Icon, Text } from '@chakra-ui/react';
import useTranslation from 'next-translate/useTranslation';

export default function NavLink({ link, ...rest }: { link: any; rest?: any }) {
  const { label, icon, href } = link;
  const { t } = useTranslation('common');

  return (
    <NextLink href={href} passHref>
      <a>
        <Flex
          align="center"
          p="4"
          mx="4"
          borderRadius="lg"
          role="group"
          cursor="pointer"
          _hover={{
            bg: 'orange.500',
          }}
          {...rest}
        >
          {icon && <Icon mr="4" fontSize="16" color="black" as={icon} />}
          <Text color="black" fontSize="1.2rem">
            {t(label)}
          </Text>
        </Flex>
      </a>
    </NextLink>
  );
}
