import { Flex, Spinner, VStack, Text } from '@chakra-ui/react';
import useTranslation from 'next-translate/useTranslation';
import React from 'react';

export default function Loading(props: { loading: any }) {
  const { t } = useTranslation('common');
  return props.loading ? (
    <Flex
      width={'100vw'}
      height={'100vh'}
      justifyContent="center"
      alignItems="center"
      overflow={'hidden'}
    >
      <VStack>
        <Spinner size="xl" speed="0.65s" />
        <Text fontSize="sm">{t('loading-text')}</Text>
      </VStack>
    </Flex>
  ) : (
    <div></div>
  );
}
